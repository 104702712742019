import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import { styled, alpha } from '@mui/material/styles'

export const ModalWrapperContainer = styled(Popover)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: `${alpha(theme.palette.common.white, 0.5)}`,
  },
  '& .MuiPopover-paper': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
}))

export const ModalPaper = styled(Box, {
  shouldForwardProp: prop => prop !== 'ownerState',
})(() => ({
  width: 'fit-content',
}))
