'use client'

import { useEffect, useRef } from 'react'

export default function ConsoleWarning() {
  // React does not guarantee that effects are executed once only, for example for partial rehydration. To try and
  // ensure that developers write safe code, React.StrictMode will always render every component twice. As there's no
  // cleanup for this hook, we should track this ourselves to ensure it executes only once.
  const warningFired = useRef(false)

  useEffect(() => {
    if (!warningFired.current) {
      warningFired.current = true

      // Fire and forget; put a message in the console
      import('console-warning').then(({ default: logConsoleWarning }) => {
        logConsoleWarning()
      })
    }
  }, [])

  return null
}
