import { createContext, useContext } from 'react'

import { ModalContextState } from 'components/molecules/Modal/ModalWrapper.types'

export const ModalContext = createContext<ModalContextState>({
  updatePosition() {},
})

export function useModalContext(): ModalContextState {
  return useContext(ModalContext)
}
