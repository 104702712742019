import { HubSpotCookieConsent } from 'components/integrations/HubSpot/HubSpot.types'

export const CookieConsentAllowAll: HubSpotCookieConsent = {
  allowed: true,
  categories: { necessary: true, analytics: true, advertisement: true, functionality: true },
}

export const CookieConsentDenyAll: HubSpotCookieConsent = {
  allowed: false,
  categories: { necessary: true, analytics: false, advertisement: false, functionality: false },
}
