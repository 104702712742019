'use client'

import React, { useMemo, useRef } from 'react'

import Fade from '@mui/material/Fade'
import { PopoverActions } from '@mui/material/Popover'
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton'
import dynamic from 'next/dynamic'

import { ModalPaper, ModalWrapperContainer } from 'components/molecules/Modal/ModalWrapper.components'
import predefinedPositions from 'components/molecules/Modal/ModalWrapper.consts'
import { ModalContext } from 'components/molecules/Modal/ModalWrapper.context'
import { ModalContextState } from 'components/molecules/Modal/ModalWrapper.types'
import { useAppDispatch, useAppSelector } from 'redesignStore'
import { resetModal } from 'redesignStore/slices/modal/modalSlice'
import { selectModalDetails } from 'redesignStore/slices/modal/modalSlice.selectors'

const defaultSkeletonLoaderProps: SkeletonProps = { width: '530px', height: '467px', variant: 'rounded' }

export default function ModalWrapper() {
  const dispatch = useAppDispatch()

  const {
    open,
    eventTargetId,
    modalComponentPath,
    modalComponentProps,
    modalComponentPosition,
    skeletonLoaderProps = defaultSkeletonLoaderProps,
    ignoreClickOutside,
  } = useAppSelector(selectModalDetails)

  const popoverActions = useRef<PopoverActions>(null)

  const CustomComponent =
    modalComponentPath && modalComponentProps
      ? dynamic<typeof modalComponentProps>(
          () => import(/* webpackExclude: /\.spec\.tsx?$/ */ `./modals/${modalComponentPath}`),
          {
            loading: () => (
              <Fade in timeout={250}>
                <Skeleton {...skeletonLoaderProps} />
              </Fade>
            ),
          }
        )
      : undefined

  const childContext: ModalContextState = useMemo(
    () => ({
      updatePosition() {
        popoverActions.current?.updatePosition()
      },
    }),
    []
  )

  return (
    <ModalContext.Provider value={childContext}>
      <ModalWrapperContainer
        TransitionComponent={Fade}
        slots={{ paper: ModalPaper }}
        slotProps={{
          root: {
            slotProps: {
              backdrop: {
                invisible: false,
              },
            },
            sx: {
              ...(!eventTargetId && {
                // Note: defaulting any modal to the center if no "id=" attr is passed to the clicked target e.g <Button id="something" ... /> (passed by eventTargetId)
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
            },
          },
          paper: {
            sx: {
              // only use position: absolute, if we aren't positioning against another element
              position: eventTargetId ? 'absolute' : 'static',
            },
          },
        }}
        open={open}
        onClose={() => {
          if (!ignoreClickOutside) {
            dispatch(resetModal())
          }
        }}
        anchorEl={(eventTargetId && document.getElementById(eventTargetId)) || undefined}
        action={popoverActions}
        anchorReference={modalComponentPosition?.anchorReference ?? 'anchorEl'}
        anchorPosition={modalComponentPosition?.anchorPosition ?? undefined}
        transformOrigin={modalComponentPosition?.transformOrigin ?? predefinedPositions.right.transformOrigin}
        anchorOrigin={modalComponentPosition?.anchorOrigin ?? predefinedPositions.right.anchorOrigin}
      >
        {CustomComponent && modalComponentProps && <CustomComponent {...modalComponentProps} />}
      </ModalWrapperContainer>
    </ModalContext.Provider>
  )
}
