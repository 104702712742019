'use client'

import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { usePathname } from 'next/navigation'

import { CookieConsentAllowAll, CookieConsentDenyAll } from 'components/integrations/HubSpot/HubSpot.consts'
import { HubSpotContext } from 'components/integrations/HubSpot/HubSpot.context'
import { HubSpotCookieConsent, HubSpotState } from 'components/integrations/HubSpot/HubSpot.types'
import { EventsAPI } from 'types/HubSpot'
import isIframe from 'utils/isIframe'

export default function HubSpotProvider({ children }: { children: ReactNode }) {
  const pathname = usePathname()

  const loadHubSpot = useCallback(
    async () =>
      new Promise<EventsAPI>((resolve, reject) => {
        if (
          !isIframe() &&
          (process.env.NEXT_PUBLIC_CHAT_WIDGET === 'true' || process.env.NEXT_PUBLIC_COOKIES_OPT_IN === 'true') &&
          typeof process.env.NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID !== 'undefined'
        ) {
          if (process.env.NEXT_PUBLIC_CHAT_WIDGET === 'true') {
            // HubSpot's chat bubble overlays our footer by default, so we want to move it. Use a MutationObserver to look for
            // elements added directly to document.body
            const onConversationsAPIReady = () => {
              if (typeof window._hsp !== 'undefined' && typeof window.HubSpotConversations !== 'undefined') {
                const observer = new MutationObserver((mutationsList, ob) => {
                  mutationsList.forEach(mutation => {
                    if (mutation.type === 'childList') {
                      Array.from(mutation.addedNodes).forEach(node => {
                        if (node instanceof HTMLElement && node.id === 'hubspot-messages-iframe-container') {
                          node.style.setProperty('bottom', '36px', 'important')
                          ob.disconnect()
                        }
                      })
                    }
                  })
                })

                // Start observing document.body to look for HubSpot's iframe
                observer.observe(document.body, { childList: true })

                // Finally, instruct HubSpot to load its widget
                window.HubSpotConversations.widget.load({ widgetOpen: false })
              }
            }

            window.hsConversationsOnReady = [onConversationsAPIReady]
            window.hsConversationsSettings = {
              loadImmediately: false,
              enableWidgetCookieBanner: false,
            }
          }

          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.id = 'hs-script-loader'
          script.async = true
          script.defer = true
          script.src = `https://js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_ACCOUNT_ID}.js`
          script.onload = () => {
            resolve(window._hsp as EventsAPI)
          }
          script.onerror = (event, source, lineno, colno, error) => {
            reject(error)
          }
          document.body.appendChild(script)
        }
      }),
    []
  )

  const [eventsApi, setEventsApi] = useState<EventsAPI>()
  const [cookieConsent, setCookieConsent] = useState<HubSpotCookieConsent>()

  useEffect(() => {
    setEventsApi(window._hsp)
    setCookieConsent(
      process.env.NEXT_PUBLIC_COOKIES_OPT_IN === 'true'
        ? isIframe()
          ? CookieConsentDenyAll
          : undefined
        : CookieConsentAllowAll
    )
  }, [])

  useEffect(() => {
    if (typeof window._hsp === 'undefined' && pathname && !pathname.startsWith('/callback')) {
      loadHubSpot()
        .then(eventsApi => {
          setEventsApi(eventsApi)
          eventsApi.push([
            'addPrivacyConsentListener',
            ({ allowed, categories }) => setCookieConsent({ allowed, categories }),
          ])
        })
        .catch(() => {
          // Do nothing
        })
    }
  }, [loadHubSpot, pathname])

  const childContext: HubSpotState = useMemo(
    () => ({
      eventsApi,
      cookieConsent,
    }),
    [eventsApi, cookieConsent]
  )

  return <HubSpotContext.Provider value={childContext}>{children}</HubSpotContext.Provider>
}
