export default function serializeRequestParams(params: Record<string, unknown>): string {
  let options = ''

  Object.entries(params).forEach(([key, value]) => {
    if (typeof value !== 'undefined' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach(el => {
          options += `${encodeURIComponent(key)}=${encodeURIComponent(el.toString())}&`
        })
      } else if (typeof value !== 'object') {
        options += `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}&`
      } else {
        console.error(`Unsupported query parameter: ${key}`)
      }
    }
  })

  return options ? options.slice(0, -1) : options
}
