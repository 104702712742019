'use client'

import { createContext, useContext } from 'react'

import { HubSpotState } from 'components/integrations/HubSpot/HubSpot.types'

export const HubSpotContext = createContext<HubSpotState>({})

export function useHubSpot(): HubSpotState {
  return useContext(HubSpotContext)
}
