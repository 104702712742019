import { MatomoAPI } from 'components/integrations/Matomo/Matomo.types'

export default class MatomoInstance implements MatomoAPI {
  private _paq: typeof window._paq

  constructor(_paq: typeof window._paq) {
    this._paq = _paq
  }

  trackPageView(href = window.location.href, documentTitle = window.document.title) {
    this._paq.push(['setCustomUrl', href])
    this._paq.push(['setDocumentTitle', documentTitle])
    this._paq.push(['trackPageView'])
  }

  setUserId(userId?: string) {
    this._paq.push(['setUserId', userId ?? ''])
  }
}
