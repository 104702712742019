'use client'

import { createContext, useContext } from 'react'

import { MatomoState } from 'components/integrations/Matomo/Matomo.types'

export const MatomoContext = createContext<MatomoState>({})

export function useMatomo(): MatomoState {
  return useContext(MatomoContext)
}
