import type { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { FetchArgs, FetchBaseQueryError, BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query'

import { getJWTCookie, setJWTCookie } from 'redesignStore/api/utils/common'
import { AppState } from 'redesignStore/index'

export type ExtraOptions = {
  workspaceEndpoint?: boolean
  workspaceUrlPath?: string
  apiVersion?: string
  forceNoActAsAccountId?: boolean
  addWorkspaceQueryParam?: boolean
}

function waitForCondition(conditionFunction: () => boolean, interval = 50) {
  return new Promise<void>(resolve => {
    const checkCondition = () => {
      if (conditionFunction()) {
        resolve()
      } else {
        setTimeout(checkCondition, interval) // Check again after a specified interval
      }
    }

    checkCondition()
  })
}

export const NoWorkspaceError = {
  error: {
    status: 'CUSTOM_ERROR',
    error: 'No workspace selected',
  } satisfies FetchBaseQueryError,
}

/**
 * Enhances baseQuery with token refresh, API version management and workspace setting
 */
export default function enhanceBaseQuery(
  baseQuery: ReturnType<typeof fetchBaseQuery>
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> {
  let refreshingToken = false
  const refreshAccessToken = async () => {
    if (!refreshingToken) {
      try {
        refreshingToken = true
        const response = await fetch(`${process.env.NEXT_PUBLIC_REDESIGN_API_BASE_URL}v1/auth/refreshToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getJWTCookie()}`,
          },
        })

        const tokenData = await response.json()

        if (response.ok) {
          const {
            accessToken,
          }: {
            accessToken: string
          } = tokenData
          setJWTCookie(accessToken)
          return true
        }

        // Not ideal, but until we have better error handling...
        window.location.replace(
          `${window.location.origin}/login/main?target=${window.location.pathname}&code=logged-out`
        )
      } catch (error) {
        console.error('Error refreshing token:', error)
        window.location.replace(
          `${window.location.origin}/login/main?target=${window.location.pathname}&code=logged-out`
        )
      } finally {
        refreshingToken = false
      }
    } else {
      await waitForCondition(() => !refreshingToken)
      return true
    }

    return false
  }

  const tryQueryWithTokenRefresh = async (
    api: BaseQueryApi,
    extraOptions: ExtraOptions,
    adjustedArgs: string | FetchArgs
  ) => {
    const response = await baseQuery(adjustedArgs, api, extraOptions)
    if (
      response.error?.status === 401 &&
      !response.meta?.request.url.endsWith('refreshToken') &&
      !response.meta?.request.url.endsWith('authEndpoints') &&
      !response.meta?.request.url.endsWith('logout')
    ) {
      const refreshSucceeded = await refreshAccessToken()
      if (refreshSucceeded) {
        return baseQuery(adjustedArgs, api, extraOptions)
      }
    }
    return response
  }

  return async (args, api, extraOptions: ExtraOptions) => {
    const apiVersion = extraOptions?.apiVersion ?? 'v1'
    const state: AppState = api.getState() as AppState
    const accessToken = getJWTCookie()

    const clientAccountId = sessionStorage.getItem('clientAccountId') ?? localStorage.getItem('clientAccountId')
    const urlEnd = typeof args === 'string' ? args : args.url
    let adjustedUrl = `${apiVersion}${urlEnd}`

    const currentWorkspace =
      state.workspace.currentWorkspaceId ?? sessionStorage.getItem('subAccount') ?? localStorage.getItem('subAccount')
    if (extraOptions?.workspaceEndpoint) {
      if (currentWorkspace) {
        adjustedUrl = `${apiVersion}/${extraOptions?.workspaceUrlPath ?? 'subAccounts'}/${currentWorkspace}${urlEnd}`
      } else {
        // Error is cached until all tags are invalidated in contextApi when login succeeds and workspace is selected
        return NoWorkspaceError
      }
    }

    const urlParams = new URLSearchParams()

    if (extraOptions?.addWorkspaceQueryParam) {
      urlParams.append('subAccountId', currentWorkspace as string)
    }

    if (clientAccountId && !extraOptions?.forceNoActAsAccountId) {
      urlParams.append('actAsAccountId', clientAccountId)
    }

    if (urlParams.size) {
      if (typeof args === 'string') {
        adjustedUrl = `${adjustedUrl}?${urlParams.toString()}`
      } else {
        args.params = { ...args.params, ...Object.fromEntries(urlParams.entries()) }
      }
    }

    const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }

    return accessToken
      ? tryQueryWithTokenRefresh(api, extraOptions, adjustedArgs)
      : baseQuery(adjustedArgs, api, extraOptions)
  }
}
