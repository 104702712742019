import { createSelector } from '@reduxjs/toolkit'

import { ModalDetails, ModalsPathMap } from 'components/molecules/Modal/ModalWrapper.types'
import { AppState } from 'redesignStore/index'
import { ModalState } from 'types/redesignStore'

function selectModalState<T extends keyof ModalsPathMap>(state: AppState) {
  return state.modal as ModalState<T>
}

function selectDetails<T extends keyof ModalsPathMap>(state: ModalState<T>): ModalDetails<T> {
  const { open, eventTargetId, modalComponent, ignoreClickOutside, eventOrigin } = state

  if (modalComponent) {
    const { modalComponentPath, modalComponentProps, modalComponentPosition, skeletonLoaderProps } = modalComponent

    return {
      open,
      eventTargetId,
      modalComponentPath,
      modalComponentProps,
      modalComponentPosition,
      skeletonLoaderProps,
      ignoreClickOutside,
      eventOrigin,
    }
  }

  return { open, eventTargetId, ignoreClickOutside }
}

export const selectModalDetails = createSelector(selectModalState, selectDetails)

export default {}
